





















































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import BlockButtons from '@/components/BlockButtons.vue'
import { ButtonAction } from '@/contracts/ButtonHeroAction'

export default defineComponent({
  name: 'BlockHeroSlide',
  components: { BlockButtons },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    menuItemShield: {
      type: String,
      default: '',
    },
    menuItemShieldStyle: {
      type: String,
      default: '',
    },
    image: {
      type: Object as PropType<{ src: string; alt: string }>,
      default: () => ({}),
    },
    imageRectangle: {
      type: Object as PropType<{ src: string; alt: string }>,
      default: () => ({}),
    },
    buttonsAction: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const sizes = {
      sm: [375, 360],
      md: [980, 360],
      lg: [980, 360],
    }

    return {
      sizes,
    }
  },
})
