

































import {
  defineComponent,
  computed,
  ref,
  useStore,
} from '@nuxtjs/composition-api'
import BlockHeroSlide from '../components/BlockHeroSlide.vue'
import { sectionCoreProps } from '@/mixins/sectionCore'
import BlockHeroSliderNavigation from '@/components/BlockHeroSliderNavigation.vue'

export default defineComponent({
  name: 'SectionHeroSlider',
  components: {
    BlockHeroSlide,
    BlockHeroSliderNavigation,
  },
  mixins: [sectionCoreProps],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore()
    const isMobileMq = computed<boolean>(
      () => store.getters['mediaQuery/isMobileMq']
    )
    const agile = ref(null)
    const slides: any = computed(() => props.list)
    const currentSlideIndex = ref(0)

    const agileOptions = computed(() => ({
      navButtons: false,
      infinite: props.list.length > 1,
      dots: false,
      slidesToShow: 1,
      swipeDistance: 50,
    }))

    const scrollToActive = (index: number) => {
      if (isMobileMq.value) {
        const b = document.getElementById('thumb-' + index)
        if (b) {
          const position = b.getBoundingClientRect()
          const screenWidth = document.body.clientWidth
          const getOffsetX = document.getElementById('scrollContainer')
          const offsetX = getOffsetX.scrollLeft
          if (position.x < -5 || position.right > screenWidth) {
            getOffsetX.scrollTo({
              left: position.left + offsetX - 15,
              behavior: 'smooth',
            })
          }
        }
      }
    }

    const prevSlideHandle = () => {
      agile.value?.goToPrev()
    }

    const nextSlideHandle = () => {
      agile.value?.goToNext()
    }

    const afterChangeSlideHandle = (index: number) => {
      currentSlideIndex.value = index
      scrollToActive(index)
    }

    const selectSlideHandle = (index: number) => {
      agile.value?.goTo(index)
    }
    return {
      prevSlideHandle,
      nextSlideHandle,
      selectSlideHandle,
      afterChangeSlideHandle,
      agile,
      agileOptions,
      slides,
      currentSlideIndex,
    }
  },
})
