



























import {
  defineComponent,
  ref,
  computed,
  onMounted,
  SetupContext,
  watch,
  onUnmounted,
} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BlockHeroSliderNavigation',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentSlideIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx: SetupContext) {
    const isPaused = ref(false)
    const intervalID = ref<any>()
    const newSlideIndex = computed(() => props.currentSlideIndex)

    const selectSlideHandle = (index: number) => {
      ctx.emit('select-slide', index)
    }

    const prevSlide = () => {
      ctx.emit('prev-slide')
    }
    const nextSlide = () => {
      ctx.emit('next-slide')
    }

    const startSlider = () => {
      intervalID.value = setInterval(() => {
        if (newSlideIndex.value > props.list.length - 1) {
          selectSlideHandle(0)
        } else if (newSlideIndex.value < 0) {
          selectSlideHandle(props.list.length - 1)
        } else {
          selectSlideHandle(newSlideIndex.value + 1)
        }
      }, 8000)
    }

    const visibilityStopSlideHandle = () => {
      if (document.hidden) {
        clearInterval(intervalID.value)
        isPaused.value = true
      } else {
        isPaused.value = false
        clearInterval(intervalID.value)
        startSlider()
      }
    }

    watch(newSlideIndex, () => {
      clearInterval(intervalID.value)
      startSlider()
    })
    onMounted(() => {
      clearInterval(intervalID.value)
      startSlider()

      document.addEventListener('visibilitychange', visibilityStopSlideHandle)
    })
    onUnmounted(() => {
      clearInterval(intervalID.value)
      document.removeEventListener(
        'visibilitychange',
        visibilityStopSlideHandle
      )
    })
    return {
      selectSlideHandle,
      prevSlide,
      nextSlide,
      isPaused,
    }
  },
})
